import * as React from 'react';
import { formatPartialDate, isPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { useAppSelector as useSelector } from 'Store/index';
import { localizeCodenames, smaLabResultTypes, srPriorityExams } from '../utils';
import { useIntl } from 'react-intl';

const ExaminationDash = ({ documents }: IOwnProps): JSX.Element => {
  const platform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);

  /** Filter documents if platform is SMA or DMD */
  const docs =
    platform && ['sma', 'dmd'].includes(platform)
      ? documents.filter(
          (d) =>
            d && d._type === 'examination' && typeof d.codeName === 'string' && smaLabResultTypes.includes(d.codeName),
        )
      : documents;

  const sortedArr = docs ? docs.sort((n1, n2) => sortPartialDate(n2.date, n1.date)) : [];

  const srPriorities = documents
    .filter((d) => d._type === 'examination' && srPriorityExams.includes(d?.codeName ?? ''))
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const srAbnormalPriorities = srPriorities.filter(
    (d) =>
      d?.abnormalFlags &&
      !docs.find((doc) => partialDateToValue(doc.date) > partialDateToValue(d.date) && doc.code === d.code),
  );

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  if (platform === 'sleepApnea') {
    const subTitle =
      srPriorities.length === 0
        ? fm('examination.noPriorities')
        : srAbnormalPriorities.length > 0
          ? fm('examination.abnormalPriorities')
          : fm('examination.noAbnormalPriorities');
    return (
      <TileContentMaker
        type="listWithSubheaders"
        data={{
          [subTitle]: {
            type: 'recentList',
            documentsLength:
              srAbnormalPriorities.length > 0
                ? srAbnormalPriorities.length
                : srPriorities.length > 0
                  ? srPriorities.length
                  : null,
            data: (srAbnormalPriorities.length > 0 ? srAbnormalPriorities : sortedArr).map((exam) => ({
              date: isPartialDate(exam.date) ? formatPartialDate(exam.date) : '',
              title: exam?.codeName ? localizeCodenames(exam?.codeName, fm) : '',
              value: exam.value && `${exam.value}${exam.units ? ' ' + exam.units : ''}`,
              key: exam?._id,
            })),
          },
        }}
      />
    );
  }

  return platform === 'mgravis' ? (
    <TileContentMaker
      type="specificList"
      data={['S-AChR-Ab', 'S-MuSKAb', 'S-TitiAb', 'LRP4_antibody', 'ryanodine_antibody', 'S-CaKPQAb'].map(
        (codeName) => {
          const doc = sortedArr.find((d) => d.codeName === codeName);
          return {
            date: doc && doc.date ? formatPartialDate(doc.date) : fm('examination.notDone'),
            key: codeName,
            title: localizeCodenames(codeName, fm),
            value: doc?.value && `${doc?.value}${doc?.units ? ` ${doc.units}` : ''}`,
          };
        },
      )}
    />
  ) : (
    <TileContentMaker
      type="recentList"
      data={sortedArr.map((d, i) => ({
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: d?.codeName ? localizeCodenames(d?.codeName, fm) : '',
        value: d.value && `${d.value}${d.units ? ' ' + d.units : ''}`,
        key: `${i}`,
      }))}
    />
  );
};

interface IOwnProps {
  documents: Array<IExamination>;
}

export default ExaminationDash;
